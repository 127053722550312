<template>
  <div class="container">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "my-Layout",
  data() {
    return {
      value: "",
      username: false,
      // active:0
    };
  },
  created(){
    // document.title = '津心办·南开旗舰店'
    // console.log()
    this.onClickRight()
  },
  methods: {
    onClickRight() {
        const u = navigator.userAgent, app = navigator.appVersion;
        //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        // console.log(isiOS)
        if (isiOS) {
          this.$store.commit('setTyPhone','ios')
        } else {
          this.$store.commit('setTyPhone','andriod')
        }
    },
  },
};
</script>

<style scoped lang='less'>
/deep/.van-nav-bar .van-icon {
  color: #000;
}
</style>